import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';

export default class extends Controller  {
  static values = {
    locale: String,
    startDate: String,
    endDate: String,
    apiUrl: String,
    clickUrl: String,
    markAsDoneUrl: String
  }

  connect() {
    this.initializeCalendar();
    this.markAsDone();
  }

  initializeCalendar() {
    const locale = this.localeValue
    const startDate = this.startDateValue
    const endDate = this.endDateValue
    const apiUrl = this.apiUrlValue
    const clickUrl = this.clickUrlValue

    let calendar = new Calendar(this.element, {
      locales: allLocales,
      locale: locale,
      plugins: [ dayGridPlugin, listPlugin, timeGridPlugin ],
      header: {
        left: 'timeGridDay,timeGridWeek,dayGridMonth,listCustom',
        center: 'title',
        right: 'prev,next '
      },
      views: {
        listCustom: {
          type: 'list',
          visibleRange: {
            start: startDate,
            end: endDate
          }
        }
      },
      events: apiUrl,
      eventLimit: 4,
      eventLimitClick: 'day',
      defaultDate: startDate,
      allDaySlot: false,
      slotDuration: '00:30:00',
      minTime: '07:00:00',
      maxTime: '20:00:00',
      nowIndicator: true,
      defaultView: 'listCustom',
      eventRender: (info) => {
        info.el.id = 'meeting-' + info.event.id
        info.el.classList.add('event-meeting')
      },
      eventClick: (info) => {
        const regex = /\d+/
        const meetingId = info.el.id.match(regex)[0]
        $.ajax({
          url: clickUrl.replace('meetingID', meetingId), 
          type: "GET",
        });
      },
    });
  
    calendar.render();
  }

  markAsDone() {
    if (this.hasMarkAsDoneUrlValue) {
      $.ajax({
        url: this.markAsDoneUrlValue,
        type: 'POST',
        dataType: 'script',
        data: { 'iterate_count': iterateCount() }
      });
    }
  }
}

function iterateCount() {
  if (document.querySelector('div[data-iteration]')) {
    return document.querySelector('div[data-iteration]').dataset.iteration
  } else {
    return 1
  }
}
