import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static values = {
    workflowId: Number,
    signatureLink: String,
    sandbox: Boolean
  }

  connect() {
    const yousign = new Yousign({
      signatureLink: this.signatureLinkValue,
      iframeContainerId: 'esign-iframe',
      isSandbox: this.sandboxValue
    });

    yousign.onSuccess((data) => {
      let signerId = data['signer_id'];
      let iframe = document.getElementById('yousign-iframe');
      let signed = document.getElementById('signed');

      this.markAsSigned(signerId);
      if (iframe) {
        iframe.remove();
      };
      signed.classList.replace('d-none', 'd-flex');
    });
  }

  markAsSigned(signerId) {
    fetch('/esign_signers/' + signerId + '/sign', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
    .then(() => {
      let actions = document.querySelectorAll("#line-workflow-" + this.workflowIdValue);
      actions.forEach(action => {
        action.remove();
      });
    }).catch(error => {
      console.log(error);
    })
  }
}
