import SwalController from "./swal_controller";

export default class extends SwalController {
  static values = { text: Object, confirm: Object, cancel: Object, buttonType: String }

  shouldFire() {
    return this.element.value !== this.element.dataset.startDate;
  }

  beforeBegin() {
    this.confirmValue = {
      url: this.confirmValue.url,
      type: this.confirmValue.type,
      remote: this.confirmValue.remote,
      data: {
        old_date_string: this.confirmValue.data.old_date_string,
        new_date_string: this.element.value
      }
    }
  }

  confirm(event) {
    super.confirm(event);
  }

  beforeEnd(result) {
    if (result.isDismissed) {
      // Need to find a way to set the datepicker to the old date if
      // the user dismissed.
    }
  }
}
