import { Controller } from "@hotwired/stimulus"

// This controller handles the onboardee clicking a "perform an action" action
// When the user clicks the action, the controller performs a fake request against a controller
// and visually validates the success to the user.
export default class extends Controller  {
  static targets = ["actionTriggerPhrase", "todoIcon", "doneIcon"]

  call(event) {
    event && event.preventDefault();

    if (this.element.dataset.done) {
      return;
    } else {
      this.addPerformingStatus();

      this.performRequest().then(response => {
        this.removePerformingStatus();
        this.markAsDone();
      });
    }
  }

  async performRequest() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ /* fake response */ });
      }, 250);
    });
  }

  addPerformingStatus() {
    this.element.dataset.performing = true;
  }

  removePerformingStatus() {
    delete this.element.dataset.performing;
  }

  markAsDone() {
    this.element.dataset.done = true;
    this.actionTriggerPhraseTarget.remove();
    this.todoIconTarget.classList.add("d-none");
    this.doneIconTarget.classList.remove("d-none");
  }
}
