import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['delivery', 'subject', 'photo', 'attachments', 'body', 'bodyWrapper']

  static values = { emailSubjectLabel: String,
                    securedPageSubjectLabel: String,
                    emailSubjectPlaceholder: String,
                    securedPageSubjectPlaceholder: String
                  }

  connect() {
    this.updateForm();
    this._makeDeliveryOptionsClickable();
  }

  updateForm() {
    this._updateInputs();
    this._updateRadioButtons();
  }

  _makeDeliveryOptionsClickable() {
    document.querySelectorAll('.mailing_resource_delivery .radio').forEach(radio => {
      radio.addEventListener('click', () => {
        radio.querySelector('input').click();
      });
    });
  }

  _updateInputs() {
    const selectedOption = this.deliveryTargets.find(radio => radio.checked) || this.deliveryTarget; // || this.deliveryTarget will be removed once FEATURE_FLAG_ENABLE_SECURED_PAGES variable is removed
    if (!selectedOption) return;

    const deliveryValue = selectedOption.value;

    switch (deliveryValue) {
      case 'sms':
        this._displaySmsOptions();
        break;
      case 'email':
        this._displayEmailOptions();
        break;
      case 'secured_page':
        this._displaySecuredPageOptions();
        break;
    }
  }

  _displaySmsOptions() {
    const previewButton = document.getElementById('new-mailing-resource-preview-button')
    if (previewButton) { hide(previewButton) };
    if (this.hasSubjectTarget) { hide(this.subjectTarget) };
    if (this.hasAttachmentsTarget) { hide(this.attachmentsTarget) };
    if (this.hasPhotoTarget) { hide(this.photoTarget) };
    if (this.hasPreviewButtonTarget) { hide(this.previewButtonTarget) };
    if (this.hasBodyTarget) {
      hide(this.attachmentsTarget);
      this.bodyWrapperTarget.setAttribute('delivery', 'sms');
      this.bodyTarget.removeAttribute("disabled");
    };
  }

  _displayEmailOptions() {
    const previewButton = document.getElementById('new-mailing-resource-preview-button')
    if (previewButton) { hide(previewButton) };
    if (this.hasSubjectTarget) {
      display(this.subjectTarget);
      this.subjectTarget.querySelector('label').innerText = this.emailSubjectLabelValue;
      this.subjectTarget.querySelector('input').setAttribute('placeholder', this.emailSubjectPlaceholderValue);
    };
    if (this.hasAttachmentsTarget) { display(this.attachmentsTarget) };
    if (this.hasPhotoTarget) { display(this.photoTarget) };
    this.bodyWrapperTarget.setAttribute('delivery', 'email');
    this.bodyTarget.setAttribute("disabled", true);
  }

  _displaySecuredPageOptions() {
    const previewButton = document.getElementById('new-mailing-resource-preview-button')
    if (previewButton) { display(previewButton) };
    if (this.hasSubjectTarget) {
      display(this.subjectTarget);
      this.subjectTarget.querySelector('label').innerText = this.securedPageSubjectLabelValue;
      this.subjectTarget.querySelector('input').setAttribute('placeholder', this.securedPageSubjectPlaceholderValue);
    };
    if (this.hasAttachmentsTarget) { hide(this.attachmentsTarget) };
    if (this.hasPhotoTarget) { display(this.photoTarget) };
    this.bodyWrapperTarget.setAttribute('delivery', 'secured_page');
    this.bodyTarget.setAttribute("disabled", true);
  }

  _updateRadioButtons() {
    this.deliveryTargets.forEach(radio => {
      const iconWrapper = radio.nextElementSibling;
      if (iconWrapper) {
        const checkedIcon = iconWrapper.querySelector('.checked_radio_button');
        const notCheckedIcon = iconWrapper.querySelector('.not_checked_radio_button');
        if (radio.checked) {
          checkedIcon.classList.remove('d-none');
          notCheckedIcon.classList.add('d-none');
        } else {
          checkedIcon.classList.add('d-none');
          notCheckedIcon.classList.remove('d-none');
        }
      }
    });
  }
}

const display = (element) => {
  element.classList.remove('d-none');
}

const hide = (element) => {
  element.classList.add('d-none');
}
