import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tab', 'content', 'template']

  connect() {
    this.load();
  }

  select(event) {
    const newTab = event.currentTarget;
    localStorage.setItem('sidebar_tab', newTab.dataset.type);
    this.changeSelectedAttributes(newTab);
    this.load();
  }

  load() {
    let currentTab = this.selectedTab();
    let template = this.templateTargets.filter((tab) => { return tab.getAttribute('data-tab') == currentTab.dataset.type; })[0];

    this.contentTarget.innerHTML = template.innerHTML;
  }

  changeSelectedAttributes(newTab) {
    this.tabTargets.forEach((tab) => { tab.setAttribute('selected', 'false')});
    newTab.setAttribute('selected', 'true');
  }

  selectedTab() {
    return this.tabTargets.filter((tab) => { return tab.getAttribute('selected') == 'true' })[0];
  }
}
