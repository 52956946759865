/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// actiontext editor
require("trix");
require("@rails/actiontext");

// stimulus JS
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/) // load all controllers from components
Stimulus.load(definitionsFromContext(context).concat(definitionsFromContext(contextComponents)))

import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'
Stimulus.register('nested-form', NestedForm)

// Loading of webpack stylesheets
import './src/application.scss';
import "../components"

// jQuery
import $ from 'jquery';
window.$ = window.jQuery = require('jquery');

// jQuery_ujs
// For Ajax request
import {} from 'jquery-ujs';

// Tracking functions
import { identifyUser, trackPageLoad } from '../components/tracking';
window.identifyUser = identifyUser;
window.trackPageLoad = trackPageLoad;

// Moment
import moment from 'moment';
window.moment = moment;

// // popper = requiered by bootstrap
// window.Popper = require('popper.js').default;
// // To stick the dropdown - display no dynamics - for responsive
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
require('bootstrap');

// Popover
if (document.querySelectorAll('[data-toggle="popover"]').length > 0){
  $('[data-toggle="popover"]').popover({
    container: 'body',
    delay: 300,
    animation: false
  });
};

// Toast - New badges notifications
import { initNotifications } from '../components/notifications'
window.initNotifications = initNotifications

// Main Components
import { initSidebar } from '../components/sidebar';
if (document.querySelector('.mobile-device')) {
  window.initSidebar = initSidebar;
}

// Flash alert
import { flash } from '../components/flashes';
window.flash = flash;

// selectize
import { initializeSelectize } from '../components/autocomplete';
import { initializeEmployeesSelectize, activeItem } from '../components/autocomplete/employees';
if (document.querySelector('.selectize')) {
  window.initializeSelectize = initializeSelectize;
  window.initializeEmployeesSelectize = initializeEmployeesSelectize;
  window.activeItem = activeItem;
}

// Collaborators page, allow to close column filters by clicking everywhere on the page
import { toggleColumnFilter } from './controllers/desktop/hr/filters/utils';
window.toggleColumnFilter = toggleColumnFilter;

// Pages functions
import {
  addNextActions,
  validateAction,
  handoverDownloaded
} from '../pages/dashboard';
window.addNextActions = addNextActions;
window.validateAction = validateAction;
window.handoverDownloaded = handoverDownloaded;

import { openResourceContent } from '../pages/dashboard';
window.openResourceContent = openResourceContent;
import { planMeeting } from '../pages/dashboard';
window.planMeeting = planMeeting;
import { destroyMeeting } from '../pages/dashboard';
window.destroyMeeting = destroyMeeting;
import { dismissAction } from '../pages/dashboard';
window.dismissAction = dismissAction;

import { scrollToAlertAction } from '../pages/dashboard';
if (document.getElementById('onboardee_dashboard')) {
  window.scrollToAlertAction = scrollToAlertAction;
  scrollToAlertAction();
}
import { filterActions } from '../pages/dashboard';
import { initShowOnboardeeBlock } from '../pages/dashboard';
import { initCloseOnboardeeBlock } from '../pages/dashboard';
import { updateCount } from '../pages/dashboard';
import { initNextActionsButton } from '../pages/dashboard';
import { uploadPaperworkDocument } from '../pages/dashboard';
if (document.getElementById('manager_dashboard')) {
  window.filterActions = filterActions
  window.initShowOnboardeeBlock = initShowOnboardeeBlock
  window.initCloseOnboardeeBlock = initCloseOnboardeeBlock
  window.updateCount = updateCount
  window.initNextActionsButton = initNextActionsButton
  window.uploadPaperworkDocument = uploadPaperworkDocument
}

import { initNavigatorSection } from '../pages/track';
window.initNavigatorSection = initNavigatorSection;
import { initSmoothScrolling } from '../pages/track';
window.initSmoothScrolling = initSmoothScrolling;
import { displayKitCoordinators } from '../pages/track';
window.displayKitCoordinators = displayKitCoordinators;
import { scrollSmoothTo } from '../pages/track';
window.scrollSmoothTo = scrollSmoothTo;
import { scrollToNextBlock } from '../pages/track';
window.scrollToNextBlock = scrollToNextBlock;
import { initStopPropagation } from '../pages/track';

// Dashboard Manager
import { updateDashboardAfterValidation } from '../pages/manager_dashboard';
window.updateDashboardAfterValidation = updateDashboardAfterValidation;
import { updateActionCountFor } from '../pages/manager_dashboard';
window.updateActionCountFor = updateActionCountFor;

// Quizzes functions
import { prevQuestion } from '../components/quiz';
window.prevQuestion = prevQuestion;

// Surveys function
import { selectSurveyAnswer } from '../components/survey';
window.selectSurveyAnswer = selectSurveyAnswer;

// Forms
import { togglePaperworksDisplay } from '../pages/forms'
window.togglePaperworksDisplay = togglePaperworksDisplay;

// Paperwork fields
import { downloadPaperworkDocument } from '../pages/paperwork_fields';
window.downloadPaperworkDocument = downloadPaperworkDocument;

// Paperwork Resources
import { changeSpecificFileData } from '../pages/paperwork_resources';
window.changeSpecificFileData = changeSpecificFileData;

// Badges functions
import { openBadgeViewFor } from '../components/badges';
if (document.getElementById("modal-badges")) {
  window.openBadgeViewFor = openBadgeViewFor;
}

// EmailTemplate functions
import { sendTestEmail } from '../pages/email_templates';
window.sendTestEmail = sendTestEmail;

// Pie chart for Overview report
import { initPieChart } from '../components/pie_chart';
window.initPieChart = initPieChart;

// Other functions
import { initBottomLoading } from '../components/bottom_loading';
window.initBottomLoading = initBottomLoading;
import { stopBottomLoading } from '../components/bottom_loading';
window.stopBottomLoading = stopBottomLoading;

// Tooltip functions
import { selectOption } from '../components/select_option';
window.selectOption = selectOption;

// Utilities functions
import { copyOnClick } from '../components/button_copy';
window.copyOnClick = copyOnClick;
import { scrollToElement } from '../components/scrolling';
window.scrollToElement = scrollToElement;

// Check email on sign in
import { verifyEmail } from '../pages/auth_strategy';
window.verifyEmail = verifyEmail;

import { updateManagerDashboardCount } from '../pages/update_dashboard_count';
window.updateManagerDashboardCount = updateManagerDashboardCount;

// ===============================================
// Fullcalendar
// ===============================================
import { Calendar } from '@fullcalendar/core';
import '@fullcalendar/core/main.min.css';
import listPlugin from '@fullcalendar/list';
import '@fullcalendar/list/main.min.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/daygrid/main.min.css';
import timeGridPlugin from '@fullcalendar/timegrid';
import '@fullcalendar/timegrid/main.min.css';
import allLocales from '@fullcalendar/core/locales-all';
import { initializeMeetAtCalendar } from '../components/calendar/calendar';
import { initCalendarD1 } from '../components/calendar/calendar';
import { initCalendarW1 } from '../components/calendar/calendar';
import { initCalendarW1Mobile } from '../components/calendar/calendar';
import { initCalendarM1 } from '../components/calendar/calendar';
import { initCalendarY1 } from '../components/calendar/calendar';
import { initializeManagerCalendar } from '../components/calendar/calendar';

if (document.getElementById('meet_at_calendar')){
  window.Calendar = Calendar
  window.dayGridPlugin = dayGridPlugin
  window.listPlugin = listPlugin
  window.timeGridPlugin = timeGridPlugin
  window.allLocales = allLocales
  window.initializeMeetAtCalendar = initializeMeetAtCalendar
  initializeMeetAtCalendar();
};
if (document.getElementById('onboardee_track')){
  window.Calendar = Calendar
  window.dayGridPlugin = dayGridPlugin
  window.listPlugin = listPlugin
  window.timeGridPlugin = timeGridPlugin
  window.allLocales = allLocales
  initStopPropagation();
  if (document.getElementById('calendar_d1')) {
    initCalendarD1();
  };
  if (document.getElementById('calendar_w1')) {
    initCalendarW1();
  };
  if (document.getElementById('calendar_w1_mobile')) {
    initCalendarW1Mobile();
  };
  if (document.getElementById('calendar_m1')) {
    initCalendarM1();
  };
  if (document.getElementById('calendar_y1')) {
    initCalendarY1();
  };
}

// Progress Bars
import { progressBarManagerDashboard, progressBarOnboardee, progressBarDashboard, progressBarOnboardeeShow, progressLineWithText, progressLineWithoutText } from '../components/progress_bar/progress_bar';
window.progressBarManagerDashboard = progressBarManagerDashboard;
window.progressBarOnboardee = progressBarOnboardee;
window.progressBarDashboard = progressBarDashboard;
window.progressBarOnboardeeShow = progressBarOnboardeeShow;
window.progressLineWithText = progressLineWithText;
window.progressLineWithoutText = progressLineWithoutText;

import jstz from 'jstz';
const timezone = jstz.determine();
document.cookie = 'browser_time_zone='+ timezone.name() + '; path=/';

// track ending
import { rocketMoveAround, celebrate } from '../pages/track_endings';
window.rocketMoveAround = rocketMoveAround;
window.celebrate = celebrate;

// user account
import { changeLocale } from '../pages/account';
window.changeLocale = changeLocale;

// Unregister the service worker if it's present
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    }).catch(function (err) {
      console.error('Service Worker registration failed: ', err);
    });
  });
}

import { stopVideo } from '../utils/clear_video';
window.stopVideo = stopVideo;

import { draggableCharts } from '../pages/desktop/hr/dashboard';
window.draggableCharts = draggableCharts;


// Phone number
// Documentation : https://github.com/jackocnr/intl-tel-input
import 'intl-tel-input/build/css/intlTelInput.css';
