import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "utils/csrf_token";


// On the actor interface, the entire DOM is no longer loaded.
// With each "page change", it is the #page-content that will evolve.

// A page can therefore be loaded in 2 ways:
// - #navigate: simple click like any other link
// - #submit : submit a form for which a redirection is expected
export default class extends Controller {
  static targets = ['pageContent']

  requestedAt = new Date();

  connect() {
    window.addEventListener('popstate', this.popstateHandler.bind(this));
  }

  disconnect() {
    window.removeEventListener('popstate', this.popstateHandler.bind(this));
  }

  // This method prevents the link from being clicked,
  // so that the #page-content is updated with the HTML content of the response.
  //
  // To add a link without fully loading the page on the actor interface, you must :
  //   1. Check in your controller can render its template without layout
  //         before_action :set_layout, only: :index
  //
  //         def index
  //           respond_to do |format|
  //             format.html { render layout: @layout }
  //           end
  //         end
  //   2. Add the following 2 data attributes to the target link or div:
  //         data-url='PATH'
  //         data-action="click->desktop--actor--navigations#navigate"
  navigate(event) {
    event.preventDefault();
    let url = event.currentTarget.dataset.url || event.currentTarget.href;
    let unsavedChanges = document.body.dataset['desktop-Actor-SavingsUnsavedChangesValue'] == 'true';

    if (unsavedChanges) {
      this.application.getControllerForElementAndIdentifier(document.getElementById('actor-interface'), 'desktop--actor--dialog').open(event.currentTarget);
    } else if (event.metaKey || event.ctrlKey) { // metaKey for Mac, ctrlKey for Windows/Linux
      window.open(url, '_blank').focus();
    } else {
      let newRequestAt = new Date();
      this.requestedAt = newRequestAt;
      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'html',
        data: { layout: 'false' },
        success: (data) => {
          this.fillPageContent(data, newRequestAt);
          this.updateHistoryUrl(url);
        }
      });
    }
  }


  // This method prevents the form from being submitted,
  // so that the #page-content is updated with the HTML content of the response.
  //
  // To add a link without fully loading the page on the actor interface, you must :
  //   1. In case of redirection, make sure that the layout is false
  //         def create
  //           Instance.save
  //           redirect_to :index, layout: false
  //         end
  //   2. Add on submit button the following data attribute :
  //         data-action="click->desktop--actor--navigations#submit"
  submit(event) {
    event.preventDefault();
    let form = event.currentTarget.form;
    let target = event.target;
    if (target.type === 'submit') {
      target.disabled = true;
    }
    let newRequestAt = new Date();
    this.requestedAt = newRequestAt;
    this.submitInsidePageContent(form, target, newRequestAt)
  }

  async submitInsidePageContent(form, target, requestedAt) {
    let methodInput = form.querySelector("[name='_method']")
    let method = (methodInput && ['put', 'patch'].includes(methodInput)) ? 'put' : 'post'
    let body = new FormData(form)
    body.append('layout', false)
    if (target.nodeName == 'INPUT' && target.type == 'submit') {
      body.append(target.name, target.value)
    }

    try {
      const response = await fetch(form.action, {
        method: method,
        body: body,
        headers: { 'X-CSRF-Token': csrfToken() }
      });

      if (response.ok) {
        const responseHTML = await response.text();
        this.fillPageContent(responseHTML, requestedAt);

        if (response.redirected) { this.updateHistoryUrl(response.url.replace('layout=false', '')) };
      } else {
        console.error('Erreur de requête:', response.status);
      }
    } catch (error) {
      console.error('Erreur:', error);
    }
  }

  async change(event) {
    event.preventDefault();
    let url = event.target.value
    let newRequestAt = new Date();
    this.requestedAt = newRequestAt;
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'html',
      data: { layout: 'false' },
      success: (data) => {
        this.fillPageContent(data, newRequestAt);
        this.updateHistoryUrl(url);
      }
    });
  }

  fillPageContent(html, requestAt) {
    if (requestAt == this.requestedAt) {
      this.pageContentTarget.innerHTML = html;
      this.pageContentTarget.scrollIntoView();
      reactivePopover();
      removeModaleFade();
    }
  }

  updateHistoryUrl(url) {
    window.history.pushState({}, '', url);
  }

  popstateHandler(event) {
    event.preventDefault();
    let newRequestAt = new Date();
    this.requestedAt = newRequestAt;
    $.ajax({
      url: event.currentTarget.location.href,
      type: 'GET',
      dataType: 'html',
      data: { layout: 'false' },
      success: (data) => {
        this.fillPageContent(data, newRequestAt);
      }
    });
  };
}

const removeModaleFade = () => {
  let fade = document.querySelector('.modal-backdrop')
  if (fade) { fade.remove(); }
  reactivateScrolling();
}

const reactivateScrolling = () => {
  let body = document.querySelector('body')
  body.classList.remove('modal-open');
}

const reactivePopover = () => {
  $('[data-toggle="popover"]').popover({
    container: 'body'
   });
}
