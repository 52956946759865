const initializeEmployeesSelectize = (selector) => {
  const url = document.querySelector(selector).dataset.url

  let basicOptions = {
    preload: true,
    create: false,
    maxItems: null,
    valueField: "id",
    labelField: "email",
    searchField: ["email", "last_name", "first_name"],
    render: {
      item: function (item, escape) {
        return itemElementFor(document.querySelector(selector), item, escape);
      },
      option: function (item, escape) {
        var label = `${item.first_name || ''} ${item.last_name}`;
        return "<div class='padded-lateral-10px padded-vertical-5px'>" + escape(label) + "</div>";
      }
    },
    load: function(query, callback) {
     $.ajax({
       url: url,
       type: 'GET',
       dataType: 'json',
       data: {
         query: query
       },
       error: function() {
         callback();
       },
       success: function(response) {
         callback(response);
       }
     });
    },
    onItemAdd: function(value, _item) {
      let itemElement = document.querySelector(`div[data-value="${value}"][data-actived]`); 
      itemElement.classList.remove('active');
      itemElement.dataset.actived = false;

      activeTheFirstItem(document.querySelector(selector), value);

      $('[data-toggle="popover"]').popover({
        container: 'body'
      });
    },
    onDelete: function(values) {
      cleanHiddenInput(document.querySelector(selector), values);
      $('.popover ').remove();
    }
  }

  $(selector).selectize(basicOptions);
};

function activeItem(item, hiddenInputId) {
  setAttributeActived(item);
  setValueInHiddenInput(hiddenInputId, item);
}

export { initializeEmployeesSelectize, activeItem };


// Return iten element composed with selectize attributes
function itemElementFor(selectizeElement, item, escape) {
  var label = `${item.first_name || ''} ${item.last_name}`;
  var elementString = document.getElementById(selectizeElement.dataset.template).innerHTML;
  let element = createElementFromHTML(
    elementString.replaceAll('{{{label}}}', escape(label)).replaceAll('{{{id}}}', item.id)
  )
  return element
}

function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

function cleanHiddenInput(selectizeElement, values) {
  let hiddenInput = document.getElementById(selectizeElement.dataset.hiddenInput);
  if (hiddenInput && (values.includes(hiddenInput.value))) {
    hiddenInput.value = '';
  }
}

function activeTheFirstItem(selectizeElement, value) {
  if (selectizeElement.parentElement.querySelectorAll('.items div[data-value]').length <= 1) {
    let firstItem = document.querySelector(`div[data-value="${value}"][data-actived]`);
    activeItem(firstItem, selectizeElement.dataset.hiddenInput)
  }
}

function setValueInHiddenInput(hiddenInputId, item) {
  let hiddenInput = document.getElementById(hiddenInputId)
  if (hiddenInput) { hiddenInput.value = item.dataset.value }  
}

function setAttributeActived(item) {
  item.parentElement.querySelectorAll('div[data-actived]').forEach(function(el) {
      el.dataset.actived = false
  })
  item.dataset.actived = true;
}
