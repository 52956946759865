import { Controller } from "@hotwired/stimulus"
import { emailRegex } from "utils/regex";

export default class extends Controller {
  static values = {
    url: String,
    valueField: String,
    labelField: String,
    withImplications: Boolean,
    withBoardee: Boolean,
    initialItem: Object,
    trackId: String,
    submitOnBlur: Boolean,
    canCreate: Boolean
  }

  connect() {
    $(this.element).selectize(this.config());
    if (this.hasInitialItemValue) { this.setInitialValue(); }
    if (this.submitOnBlurValue) { this.addBlurSubmit(); }
  }

  setInitialValue() {
    const selectize = this.element.selectize;
    const initialItem = this.initialItemValue;
    const intiialValue = initialItem[this.valueFieldValue];

    selectize.addOption(initialItem);
    selectize.setValue(intiialValue, true);
  }

  basicConfig() {
    return {
      preload: false,
      onFocus: () => { this.loadOptions(null, null) },
      load: (query, callback) => { this.loadOptions(query, callback) },
      create: false,
      maxItems: 1,
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: ['id', 'first_name', 'last_name', 'label', 'email', 'external_id', 'role_name'],
      render: {
        item: (item, escape) => {
          return item.first_name ? this.employeeItemFor(item, escape) : this.roleItemFor(item, escape)
        },
        option: (item, escape) => {
          return item.first_name ? this.employeeOptionFor(item, escape) : this.roleOptionFor(item, escape)
        }
      }
    }
  }

  addBlurSubmit() {
    let selectize = this.element.selectize

    // Add event listener on change event, to submit if there is a value
    selectize.on('change', () => {
      let currentValue = selectize.getValue();

      if (!!currentValue) {
        $(this.element.form).submit();
      }
    });
    // Add event listener on blur event, to submit if there is none value
    selectize.on('blur', () => {
      let currentValue = selectize.getValue();
      let lastQuery = selectize.lastQuery

      if (!currentValue && !lastQuery) {
        $(this.element.form).submit();
      }
    });
  }

  loadOptions(query, callback) {
    $.ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'json',
      data: {
        query: query,
        with_implications: this.withImplicationsValue,
        with_boardee: this.withBoardeeValue,
        track_id: this.trackIdValue
      },
      error: () => {
        console.log('Error')
      },
      success: (response) => {
        callback ? callback(response) : this.seedOptionsFrom(response)
      }
    });
  }

  seedOptionsFrom(data) {
    let selectize = this.element.selectize;
    data.forEach(el => selectize.addOption(el));
    selectize.open();
  }

  config() {
    let options = this.basicConfig();
    if (this.canCreateValue) { options = this.addCreateConfigTo(options) }

    return options
  }

  roleOptionFor(item, escape) {
    return `
      <div style="padding: 6px 15px;">
        ${escape(item.label)}
      </div>
    `
  }

  employeeOptionFor(item, escape) {
    return `
      <div style="padding: 6px 15px;">
        <span>${escape(item.first_name)} ${escape(item.last_name)}</span>
        ${item.role_name ? `<span> (${escape(item.role_name)})</span>` : ''}
        ${item.label ? `<span class='label'>${escape(item.label)}</span>` : ''}
        <br><span class='padded-left-10px '>${escape(item.email)}</span>
        ${item.external_id ? `<br><span class='padded-left-10px '>${escape(item.external_id)}</span>` : ''}
      </div>
    `
  }

  // \u2039 is <
  // \u203a is >
  roleItemFor(item, escape) {
    return `
      <div>
        ${escape(item.label)}
      </div>
    `
  }

  // \u2039 is <
  // \u203a is >
  employeeItemFor(item, escape) {
    return `
      <div>
        <span>${escape(item.first_name)} ${escape(item.last_name)}</span>
      </div>
    `
  }

  addCreateConfigTo(options) {
    return {
      ...options,
      create: function (input){
        return { id: input, email: input, label: input };
      },
      createOnBlur: true,
      createFilter: emailRegex,
      onDropdownOpen: (dropdown) => {
        // Remove the dropdown, if propose to 'Add [newOption]'
        dropdown[0].querySelector('.selectize-dropdown-content .create') ? dropdown.addClass('d-none') : dropdown.removeClass('d-none')
      }
    }
  }
}
