import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.element.setAttribute('extended',  localStorage.getItem('navbar_extended')|| 'true')
  }

  connect() {
    document.addEventListener('click', this.updateNavbar.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.updateNavbar.bind(this));
  }

  extend() {
    this.element.setAttribute('extended', true)
    localStorage.setItem('navbar_extended', true)
  }

  toggleExtended() {
    let extendedValue = this.element.getAttribute('extended') != 'true'
    this.element.setAttribute('extended', extendedValue)
    localStorage.setItem('navbar_extended', extendedValue)
  }

  removeActiveLinks() {
    document.querySelectorAll('section, [link]').forEach((link) => markAsInactive(link));
  }

  updateNavbar(event) {
    let target = event.target;
    let parent = target.parentElement;

    while (parent) {
      if (parent.hasAttribute('data-link-target')) {
        let link = document.querySelector(`[link=${parent.getAttribute('data-link-target')}]`)
        markLinkAsActive(link);
        break;
      }
      parent = parent.parentElement;
    }
  }
}

export const markLinkAsActive = (element) => {
  let unsavedChanges = document.body.dataset['desktop-Actor-SavingsUnsavedChangesValue'] == 'true'
  if (unsavedChanges) { return }

  let section = element.closest('section');
  moveActiveClass(section, 'navbar section');
  let link = element.closest('[link]') || element.querySelector('[link]');
  moveActiveClass(link, 'navbar [link]')
}

const markAsActive = (element) => {
  if (element) { element.classList.add('active') }
}

const markAsInactive = (element) => {
  if (element) { element.classList.remove('active') }
}

const moveActiveClass = (link, disactive_selector) => {
  document.querySelectorAll(disactive_selector).forEach((link) => markAsInactive(link));
  markAsActive(link);
}
