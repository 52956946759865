import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "container"]
  static values = { currentSignInAt: String }

  click(event) {
    event.preventDefault();

    this.activeLink = this.findClickedLink(event);
    this.activeContainer = this.findActiveContainer(this.activeLink);

    this.fetchContainerContent();
    this.changeActiveLink();
    this.changeActiveContainer();
  }

  // Given a click event, return the clicked link amongst the targets
  findClickedLink(event) {
    return this.linkTargets.find(link =>
      link == event.target);
  }

  // Given a link, return the related container amongst the targets
  findActiveContainer(link) {
    return this.containerTargets.find(container =>
      container.dataset.id == link.dataset.id);
  }

  // === LINKS MANAGEMENT ===
  changeActiveLink() {
    this.linkTargets.map(link => this.unsetActiveLink(link));
    this.setActiveLink(this.activeLink);
  }

  // Given a link, sets its active status
  setActiveLink(link) {
    link.dataset.active = true;
  }

  // Given a link, unsets its active status
  unsetActiveLink(link) {
    delete link.dataset.active;
  }

  // === CONTAINERS MANAGEMENT ===
  changeActiveContainer() {
    this.containerTargets.map(container => this.unsetActiveContainer(container));
    this.setActiveContainer(this.activeContainer);
  }

  // Given a container, sets its active status
  setActiveContainer(container) {
    container.dataset.active = true;
  }

  // Given a container, unsets its active status
  unsetActiveContainer(container) {
    delete container.dataset.active;
  }

  // === FETCHING CONTENT ===
  fetchContainerContent() {
    if (this.activeContainer.childElementCount === 0) {
      this.fetchContent(this.activeLink.href)
          .then(html => {
            this.activeContainer.innerHTML = html;
          });
    }
  }

  async fetchContent() {
    return new Promise((resolve) => {
      resolve("<p>content fetched from Stimulus controller</p>");
    });
  }
}
