import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submit']

  delay = 400 // ms

  connect() {
    this._search();
  }

  // Call on input event
  updateSearch() {
    // Kill the previous delay
    window.clearTimeout(this.timer);
    // Set the new search delay
    this.timer = window.setTimeout(() => { this._search() }, parseInt(this.delay))
  }

  _search() {
    this.submitTarget.click();
  }
}
