import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { loadResourceFilters } from 'components/tabulator/apply_filters';
import { updateCounter, escapeHtml, displayUnscopedEnvironmentMessage } from 'components/tabulator/utils';

export default class extends Controller {
  static targets = ['resources']
  static values = {
    url: String,
    resourcesScoped: Boolean
  }

  connect() {
    this.displayPlaceholder();
    this.buildTabulator({ resourcesScoped: this.resourcesScopedValue });
  }

  displayPlaceholder() {
    this.resourcesTarget.innerHTML = document.getElementById('placeholder').innerHTML;
    document.getElementById('resources').innerHTML = document.getElementById('placeholder').innerHTML;
  }

  buildTabulator(config = { resourcesScoped: false }) {
    let currentController = this;
    const initialSort = JSON.parse(localStorage.getItem('resources_sorters'));
    const translations = JSON.parse(this.resourcesTarget.parentElement.dataset.translations);
    const tabulator = new Tabulator('#resources', {
      layout: 'fitColumns',
      langs: { 'fr-fr': {} },
      initialSort: initialSort || [{ column: 'name', dir: 'asc' }],
      initialFilter: loadResourceFilters(),
      movableColumns: false,
      placeholder: blankState(),
      height: '100%',
      responsiveLayout: 'hide',
      filterMode: 'remote',
      sortMode: 'remote',
      paginationMode: 'remote',
      pagination: true,
      paginationSize: 20,
      paginationCounter: function (pageSize, currentRow) {
        return `${translations['results']} ${currentRow} ${translations['to']} ${currentRow + pageSize - 1}`;
      },
      ajaxURL: this.urlValue,
      ajaxConfig: {
        method: "POST",
        headers: {
          'X-CSRF-Token': csrfToken()
        }
      },
      ajaxResponse: function (url, params, response) {
        tabulator.totalRows = response['count'] ?? 0;
        let count = response['count'] ?? 0;
        updateCounter('resources', count);
        return response; //return the response data to tabulator
      },
      columns: [
        { field: 'id', visible: false },
        { field: 'resource_id', visible: false },
        { field: 'url', visible: false },
        { field: 'destroy_url', visible: false },
        { field: 'onboarding', visible: false },
        { field: 'crossboarding', visible: false },
        { field: 'offboarding', visible: false },
        { field: 'mobilities', visible: false },
        { field: 'updated_at_value', visible: false },
        { title: '', field: 'bulk', width: 10, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, download: false },
        { title: '', field: 'resource_icon', width: 80, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'center', resizable: false, responsive: 0, download: false },
        { title: translations['title'], field: 'name', minWidth: 300, widthGrow: 3, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 0 },
        { title: translations['resource_type'], field: 'resource_label', width: 150, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 2, download: true },
        { title: translations['environment'], field: 'multiple_unit_organizations', visible: config['environment'], minWidth: 200, widthGrow: 1, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 1, download: false },
        { title: translations['environment'], field: 'unit_organization_names', visible: false, download: true },
        { title: translations['edited_at'], field: 'updated_at', width: 120, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'center', resizable: false },
        { title: translations['templates'], field: 'templates_count', width: 100, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, sorter: 'number' },
        { title: translations['tracks'], field: 'tracks_count', width: 80, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'center', resizable: false, sorter: 'number' },
        { title: '', field: 'actions', width: 100, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'center', resizable: false, responsive: 0 }
      ],
      rowFormatter: function (row) {
        var data = row.getData();
        var rowEl = row.getElement();
        const controller = document.querySelector('div[data-controller*="desktop--settings--resources--creation-sidebar"]');
        const canEditSharedResources = controller.dataset.sharedAuthorization == 'true';
        rowEl.classList.add('d-flex', 'align-items-center', 'padded-vertical-10px');
        rowEl.dataset.url = data.url;
        rowEl.dataset.shared = data.unit_organization_ids.length === 0;
        currentController.renderHtmlFor(rowEl, data);
      },
      downloadConfig: { columnHeaders: true }
    });
    const locale = document.querySelector('html').lang
    tabulator.on('dataSorted', function (sorters, rows) {
      const saved_sorters = [];
      sorters.forEach((sorter) => {
        saved_sorters.push({ column: sorter.column.getField(), dir: sorter.dir });
      });
      localStorage.setItem('resources_sorters', JSON.stringify(saved_sorters));
    });
    setTimeout(() => {
      tabulator.setLocale(`${locale}-${locale}`);
      updateCounter('resources', 0);
    }, 100);
  }

  renderHtmlFor(row, data) {
    // the following object is used to render the html for the row
    // the key is the id of the template tag AND the anchor to replace the html of the tabulator row
    // the values are the attributes to be replaced in the html, matching the properties of the data we receive
    const matchingObj = {
      resource_icon: ["resource_icon"],
      name: ["name", "name", "onboarding", "crossboarding", "offboarding"],
      resource_label: ["resource_label"],
      multiple_unit_organizations: ["full_unit_organization_count", "unit_organization_names", "unit_organization_names", "resource_id", "resource_type"],
      updated_at: ["updated_at"],
      templates_count: ["templates_count", "resource_id", "resource_type"],
      tracks_count: ["tracks_count"],
      actions: ["tracks_count", "resource_id", "resource_type", "show_preview", "destroy_url", "resource_id", "resource_type", "resource_id", "resource_type"]
    };

    const controller = document.querySelector('div[data-controller*="desktop--settings--resources--creation-sidebar"]');
    const canEditSharedResources = controller.dataset.sharedAuthorization == 'true';
    const canEditEnvResources = controller.dataset.envAuthorization == 'true';

    const userScope = JSON.parse(controller.dataset.envIds);
    const resourceScope = data.full_unit_organization_ids;
    const unscopedUnitOrganizationCount = resourceScope.filter((x) => !userScope.includes(x)).length
    const authorizedScope = unscopedUnitOrganizationCount === 0;

    const sharedResource = data.full_unit_organization_ids.length === 0;
    const singleEnvResource = data.unit_organization_ids.length === 1;

    let editAuthorization = false;
    Object.keys(matchingObj).forEach(templateId => {
      let selectedTemplateId = templateId;

      if (templateId === 'multiple_unit_organizations') {
        if (sharedResource || singleEnvResource) { selectedTemplateId = 'single_unit_organization'; }

      } else if (templateId === 'actions') {
        if (sharedResource && !canEditSharedResources) { selectedTemplateId = 'actions_unauthorized_edition_on_shared'; }

        else if (!sharedResource && !canEditEnvResources) { selectedTemplateId = 'actions_unauthorized_edition_on_environment'; }

        else if (!sharedResource && canEditEnvResources && !authorizedScope) { selectedTemplateId = 'actions_unauthorized_edition_on_scope'; }

        else { editAuthorization = true; }
      }

      let finalHtml = document.getElementById(selectedTemplateId).innerHTML;
      const icon = document.getElementById('icon_' + data.resource_type).innerHTML;
      finalHtml = displayUnscopedEnvironmentMessage(finalHtml, singleEnvResource, unscopedUnitOrganizationCount)
      matchingObj[templateId].forEach(attribute => {
        if (attribute === 'resource_icon') {
          finalHtml = finalHtml.replace(`{{resource_icon}}`, icon);
        } else {
          finalHtml = finalHtml.replace(`{{${attribute}}}`, escapeHtml(data[attribute]));
        }
      });
      finalHtml = finalHtml.replace(`{{resource_mobilities}}`, mobilitiesFor(data));
      finalHtml = finalHtml.replace(`{{resource_in_shared_environment}}`, sharedResource);
      row.querySelector(`[tabulator-field="${templateId}"]`).innerHTML = finalHtml;
    });
    row.dataset.edit = editAuthorization;
    if (editAuthorization) {
      row.setAttribute('data-action', 'click->desktop--settings--resources--resources#openResource');
      row.classList.add('pointer');
    }
  }

  openResource(event) {
    const row = event.currentTarget;
    const tooltip = row.querySelector('.menu-tooltip');
    if (tooltip && tooltip.getAttribute('opened') === 'true') {
      return;
    } else if (row.dataset.edit == 'true') {
        const url = row.dataset.url;
        if (event.metaKey || event.ctrlKey) { // metaKey for Mac, ctrlKey for Windows/Linux
          window.open(url, '_blank').focus();
        } else {
          window.open(url, '_self');
        }
    } else {
      row.querySelector('.fa-eye').click();
    }
  }

  export() {
    const tabulator = Tabulator.findTable('#resources')[0];
    if(tabulator.getPageMax() < 2) {
      tabulator.download('csv', 'export_resources.csv', { sheetName: 'export' });
    }else{
      const filters = tabulator.getFilters();
      const data = {
        filters: filters,
        export: 'all'
      };

      fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        return response.json();
      })
      .then(data => {
        flash(data["message"])
      }).catch(error => {
        console.log(error);
      })
    }
  }
}

function buildInitialFilter(){
  const environmentsFilter = getLocalStorageItem('forms_environments');
  const formStatusesFilter = getLocalStorageItem('forms_form_statuses');
  const datesFilter = getLocalStorageItem('forms_dates');
  const query = getLocalStorageItem('forms_query') || '';
  const my_forms = getLocalStorageItem('my_forms_switch');

  let filters = [
    { field: 'environment_id', type: 'in', value: environmentsFilter.filter(el => Number(el)).map(Number) },
    { field: 'status_key', type: 'in', value: formStatusesFilter },
    { field: 'date', type: 'in', value: datesFilter },
    { field: 'collaborator_full_name', type: 'like', value: String(query).trim(), matchAll: true },
    { field: 'my_forms', type: 'in', value: my_forms }
  ];
  return filters;
}

function blankState() {
  return document.querySelector(`[data-code="no_resources_loaded"]`).innerHTML;
}

const mobilitiesFor = (data) => {
  let mobilities = ['onboarding', 'crossboarding', 'offboarding'].filter((mobility) => data[mobility])
  return mobilities.join(',')
}
