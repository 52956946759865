import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { updateCounter, escapeHtml, displayUnscopedEnvironmentMessage, unitOrganizationSorter } from 'components/tabulator/utils';
import { applyTemplatesFilters } from 'components/tabulator/apply_filters'

export default class extends Controller {
  static targets = ['templates']
  static values = {
    url: String,
    resourcesScoped: Boolean
  }

  connect() {
    this.displayPlaceholder();
    this.buildTabulator({ resourcesScoped: this.resourcesScopedValue });
  }

  displayPlaceholder() {
    this.templatesTarget.innerHTML = document.getElementById('placeholder').innerHTML;
  }

  buildTabulator(config = { resourcesScoped: false }) {
    let currentController = this;
    const initialSort = JSON.parse(localStorage.getItem('templates_sorters'));
    const translations = JSON.parse(this.templatesTarget.parentElement.dataset.translations);
    const tabulator = new Tabulator('#templates', {
      layout: 'fitColumns',
      langs: { 'fr-fr': {} },
      initialSort: initialSort || [{ column: 'name', dir: 'asc' }],
      movableColumns: false,
      placeholder: blankState(),
      height: '100%',
      responsiveLayout: 'hide',
      filterMode: 'remote',
      sortMode: 'remote',
      paginationMode: 'remote',
      pagination: true,
      paginationSize: 20,
      paginationCounter: function (pageSize, currentRow) {
        let totalRows = tabulator.totalRows;
        if (totalRows === 0) { return ''; }
        let toCount = Math.min(currentRow + pageSize - 1, totalRows);
        return `${translations['results']} ${currentRow} ${translations['to']} ${toCount}`;
      },
      ajaxURL: this.urlValue,
      ajaxConfig: {
        method: "POST",
        headers: {
          'X-CSRF-Token': csrfToken()
        }
      },
      ajaxResponse: function (url, params, response) {
        tabulator.totalRows = response['count'] ?? 0;
        let count = response['count'] ?? 0;
        updateCounter('templates', count);
        return response; //return the response data to tabulator
      },
      columns: [
        { field: 'id', visible: false },
        { field: 'url', visible: false },
        { field: 'destroy_url', visible: false },
        { field: 'updated_at_value', visible: false },
        { field: 'remove', visible: false, cellClick: (_e, cell) => { cell.getRow().delete() } }, // to remove line of the table
        // { title: '', field: 'bulk', width: 10, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
        { title: '', field: 'photo_url', width: 80, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 0 },
        { title: translations['name'], field: 'name', minWidth: 150, widthGrow: 3, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 0 },
        { title: '', field: 'bulk', width: 10, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
        { title: translations['mobility'], field: 'mobility', width: 150, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 2 },
        { title: translations['environment'], field: 'multiple_unit_organizations', visible: config['resourcesScoped'], minWidth: 150, widthGrow: 1, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 1, sorter: unitOrganizationSorter },
        { title: '', field: 'bulk', width: 10, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
        { title: translations['edited_at'], field: 'updated_at', width: 150, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
        { title: translations['ongoing_track'], field: 'template_assignments_count', width: 80, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, sorter: 'number' },
        { title: '', field: 'actions', width: 65, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false, responsive: 0 }
      ],
      rowFormatter: function (row) {
        var data = row.getData();
        var rowEl = row.getElement();
        const controller = document.querySelector('div[data-controller*="desktop--actor--library--templates--data"]')
        const canEditSharedResources = controller.dataset.sharedAuthorization == 'true';
        currentController.renderHtmlFor(rowEl, data);
        rowEl.classList.add('d-flex', 'align-items-center')
        rowEl.setAttribute('data-id', data.id.replace('employee_', ''))
      },
      downloadConfig: { columnHeaders: true }
    });
    applyTemplatesFilters();
    tabulator.environment = config['resourcesScoped'];
    const locale = document.querySelector('html').lang
    tabulator.on('dataSorted', function (sorters, rows) {
      const saved_sorters = [];
      sorters.forEach(sorter => {
        saved_sorters.push({ column: sorter.column.getField(), dir: sorter.dir });
      });
      localStorage.setItem('templates_sorters', JSON.stringify(saved_sorters));
    });
    setTimeout(() => {
      tabulator.setLocale(`${locale}-${locale}`);
      updateCounter('templates', 0);
    }, 100);
  }

  renderHtmlFor(row, data) {
    // the following object is used to render the html for the row
    // the key is the id of the template tag AND the anchor to replace the html of the tabulator row
    // the values are the attributes to be replaced in the html, matching the properties of the data we receive
    const matchingObj = {
      photo_url: ["photo_url"],
      name: ["name", "reference_date_type_label"],
      mobility: ["mobility_label", "mobility", "mobility_logo"],
      multiple_unit_organizations: ["full_unit_organization_count", "unit_organization_name", "unit_organization_name", "resource_id", "resource_type", "authorized_scope"],
      updated_at: ["updated_at"],
      template_assignments_count: ["template_assignments_count"],
      actions: ["url", "show_url", "destroy_url", "resource_id"]
    };
    const controller = document.querySelector('div[data-controller*="desktop--actor--library--templates--data"]')
    const canEditSharedResources = controller.dataset.sharedAuthorization == 'true';
    const canEditEnvResources = controller.dataset.envAuthorization == 'true';
    const unscopedUnitOrganizationCount = data.unscoped_unit_organization_count;
    const authorizedScope = unscopedUnitOrganizationCount == 0;
    const sharedResource = data.unit_organization_ids.length === 0;
    const singleEnvResource = data.unit_organization_ids.length === 1;

    let editAuthorization = false;
    Object.keys(matchingObj).forEach(templateId => {
      let selectedTemplateId = templateId;
      if (templateId === 'multiple_unit_organizations') {
        if (sharedResource || singleEnvResource) { selectedTemplateId = 'single_unit_organization'; }

      } else if (templateId === 'actions') {
        if (sharedResource && !canEditSharedResources) { selectedTemplateId = 'actions_no_edit'; }

        else if (!sharedResource && !canEditEnvResources) { selectedTemplateId = 'actions_no_edit'; }

        else if (!sharedResource && canEditEnvResources && !authorizedScope) { selectedTemplateId = 'actions_no_edit'; }

        else { editAuthorization = true; }
      }
      let finalHtml = document.querySelector(`template#${selectedTemplateId}`).innerHTML;
      finalHtml = displayUnscopedEnvironmentMessage(finalHtml, singleEnvResource, unscopedUnitOrganizationCount)
      matchingObj[templateId].forEach(attribute => {
        finalHtml = finalHtml.replaceAll(`{{${attribute}}}`, escapeHtml(data[attribute]));
      });
      row.querySelector(`[tabulator-field="${templateId}"]`).innerHTML = finalHtml;
    });
    if (editAuthorization) {
      row.setAttribute('data-action', 'click->desktop--actor--library--templates--data#openResource');
      row.classList.add('pointer');
    }
  }

  openResource(event) {
    const tooltip = event.currentTarget.querySelector('.menu-tooltip');
    if (tooltip && tooltip.getAttribute('opened') === 'true') {
      return;
    } else {
      const element = event.currentTarget.querySelector('a[open-resource]');
      if (event.metaKey || event.ctrlKey) { // metaKey for Mac, ctrlKey for Windows/Linux
        window.open(element.href, '_blank').focus();
      } else {
        element.click();
      }
    }
  }

  openDuplicationModal(event) {
    event.preventDefault();
    $.ajax({ url: event.target.dataset.url, type: 'GET', dataType: 'script' });
  }
}

function blankState() {
  return document.querySelector(`[data-code="no_resources_loaded"]`).innerHTML;
}
