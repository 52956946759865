import { Controller } from "@hotwired/stimulus";
import { replaceValues } from './../../../../pages/sidebar_utils'

// This controller handles the track sidebar from the actor's track page.
// There is another that handles it from the action list page and the dashboard page (analyse section)
//  -> app/javascript/packs/controllers/desktop/hr/charts/track_sidebars_controller.js
export default class extends Controller {
  select(event) {
    const trackLine = event.currentTarget;
    const avatar = trackLine.querySelector('.avatar-small');
    const mobility = trackLine.dataset.mobility;
    const lineIsActive = trackLine.classList.contains('active');
    this.unselectAllTrackLines();
    if(avatar) {
      avatar.classList.toggle(`border-${mobility}`);
    }
    trackLine.classList.toggle('active');

    if (lineIsActive) {
      return;
    }

    this.activateSidebar(trackLine);
  }

  activeSmartFilter() {
    const smartFilter = document.querySelector('.smart-filter-container.active');
    let activeSmartFilter;
    if (smartFilter){
      activeSmartFilter = smartFilter.dataset['desktop-Hr-Filters-SmartFiltersTarget'];
    } else {
      activeSmartFilter = undefined;
    }
    return activeSmartFilter;
  }

  activeSubSmartFilter() {
    const subSmartFilter = document.querySelector('.subfilter.active');
    let activeSubSmartFilter;
    if (subSmartFilter) {
      activeSubSmartFilter = subSmartFilter.dataset['desktop-Hr-Filters-SmartFiltersTarget'];
    } else {
      activeSubSmartFilter = undefined;
    }
    return activeSubSmartFilter;
  }

  unselectAllTrackLines() {
    const lines = document.querySelectorAll('.tabulator-row.active');
    lines.forEach(line => {
      const avatar = line.querySelector('.avatar-small');
      const mobility = line.dataset.mobility;
      line.classList.remove('active');
      avatar.classList.remove(`border-${mobility}`);
    });
  }

  activateSidebar(trackLine) {
    const smartFilter = trackLine.dataset.trackedSub_smartfilter;
    loadSidebarContent(trackLine, smartFilter);
    displaySidebar();
  }
}

const loadSidebarContent = (trackLine, smartFilter) => {
  let backupTab = defineBackupTab(trackLine.dataset.status);
  let content = document.getElementById('template-sidebar-content').innerHTML;
  let matches = {
    '{{{trackId}}}': trackLine.dataset.trackId,
    '{{{employeeId}}}': trackLine.dataset.employeeId,
    '{{{mobility}}}': trackLine.dataset.mobility,
    '{{{status}}}': trackLine.dataset.status,
    '{{{alertActivated}}}': isAlertTab(smartFilter, backupTab),
    '{{{administrativeActivated}}}': isAdministrativeTab(smartFilter, backupTab),
    '{{{feedbacksActivated}}}': isFeedbackTab(smartFilter, backupTab),
    '{{{actionsActivated}}}': isActionTab(smartFilter, backupTab)
  }

  document.getElementById('hr-sidebar').innerHTML = replaceValues(content, matches);
}

const displaySidebar = () => {
  const sidebar = document.getElementById('hr-sidebar');
  if (sidebar.getAttribute('activated') == 'false') {
    sidebar.setAttribute('activated', 'true');
  }
}

export const isAdministrativeTab = (activeSmartFilter, backupTab) => {
  if (!activeSmartFilter) {
    return backupTab === 'administrative';
  } else {
    return ['paperwork', 'paperworkToValidate', 'paperworkCompleted', 'paperworkUncompleted'].includes(activeSmartFilter)
  }
}

export const isAlertTab = (activeSmartFilter, backupTab) => {
  if (!activeSmartFilter) {
    return backupTab === 'alert';
  } else {
    return ['alert', 'alertPending', 'alertDisengaged'].includes(activeSmartFilter)
  }
}

export const isFeedbackTab = (activeSmartFilter, backupTab) => {
  if (!activeSmartFilter) {
    return backupTab === 'feedbacks';
  } else {
    return ['feedback', 'feedbackLowPulse', 'feedbackHighPulse', 'feedbackCompletedSurvey'].includes(activeSmartFilter)
  }
}

export const isActionTab = (activeSmartFilter, backupTab) => {
  if (!activeSmartFilter) {
    return backupTab === 'actions';
  } else {
    return ['action', 'actionToDo'].includes(activeSmartFilter)
  }
}

export const defineBackupTab = (trackStatus) => {
  return ['closed', 'building', 'cancelled'].includes(trackStatus) ? 'alert' : localStorage.getItem('sidebar_tab') || 'alert';
}
